import React from 'react';

export const HotPotItemWrapper = ({ children }) => (
  <div className='flex flex-col justify-center items-center p-6 rounded-xl bg-white bg-opacity-5 ring-1 ring-white ring-opacity-10 backdrop-blur-sm shadow-2xl'>
    {children}
  </div>
);

export const HotPotItemImageWrapper = ({ children }) => (
  <div className='p-1 w-56 w-56 mb-2'>
    {children}
  </div>
);

export const HotPotItemImage = ({ src, alt, ...rest }) => (
  <img
    {...rest}
    src={src}
    alt={alt}
    className="drop-shadow-hotpot"
    onContextMenu={(e) => e.preventDefault()}
  />
);

export const HotPotItemTitle = ({ children }) => (
  <div className='text-lg text-little-pot-gold uppercase'>
    {children}
  </div>
);

export const HotPotItemPrice = ({ children }) => (
  <div className='text-md text-stone-100'>
    {children}
  </div>
);

export const HotPotItemNotes = ({ children }) => (
  <div className='flex mt-2 text-md'>
    {children}
  </div>
);

export const HotPotItemNoteWrapper = ({ children }) => (
  <div className='flex mx-1'>
    {children}
  </div>
);

export const HotPotItemIngredientsWrapper = ({ children }) => {
  <div className=''>
    {children}
  </div>
};

// export const HotPot