export const NormalItemsWrapper = ({ children }) => (
  <div className='flex flex-col gap-2'>
    {children}
  </div>
);

export const NormalItemWrapper = ({ endOfSection, children }) => {
  const margin = endOfSection ? 'mb-4' : '';
  return (
    <div className={`flex justify-between ${margin}`}>
      {children}
    </div>
  )
};

export const NormalItemTitleWrapper = ({ children }) => (
  <div className='flex gap-1 items-center'>
    {children}
  </div>
);

export const NormalItemTitle = ({ children }) => (
  <span className='text-stone-100'>
    {children}
  </span>
);

export const NormalItemNotes = ({ children }) => (
  <div className='flex text-md'>
    {children}
  </div>
);

export const NormalItemNote = ({ children }) => (
  <span className='flex mx-1'>
    {children}
  </span>
);

export const NormalItemPrice = ({ children }) => (
  <span className='text-stone-100'>
    {children}
  </span>
);