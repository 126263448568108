import { useTranslation } from 'react-i18next';

export const LanguageSwitch = () => {

  const { i18n } = useTranslation();
  const checked = i18n.language === 'zh' ? false : true;

  function handleChange(e) {
    if (i18n.language === 'en') {
      i18n.changeLanguage('zh');
    } else {
      i18n.changeLanguage('en');
    }
  }

  return (
    <div className="flex justify-center">
      <div className="flex form-check form-switch items-center">
        <div className="form-check-label inline-block text-little-pot-gold text-xs">中</div>
        <label htmlFor="toggle-example" className="flex items-center cursor-pointer relative mx-1">
          <input type="checkbox" id="toggle-example" className="sr-only" onChange={e => handleChange(e)} checked={checked} />
          <div className="toggle-bg bg-black border border-little-pot-gold h-3.5 w-6 rounded-full"></div>
        </label>
        <div className="form-check-label inline-block text-little-pot-gold text-xs">En</div>
      </div>
    </div>
  )
};

export const MenuContainer = ({ children }) => (
  <div className='min-h-full text-neutral-100 z-10'>
    {children}
  </div>
);

export const MenuBackground = ({ children }) => (
  <div className='container-bg'>
    {children}
  </div>
);

export const MenuWrapper = ({ children }) => (
  <div className='flex flex-col justify-center gap-8 p-8 z-10'>
    {children}
  </div>
);

export const MenuTitle = ({ children }) => (
  <div className='text-little-pot-gold text-4xl font-title-text uppercase'>
    {children}
  </div>
);