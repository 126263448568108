import React from "react";

import { FeatureMenuContainer } from "./FeatureMenuElements";

export default function FeatureMenu(props) {
  return (
    <>
      <FeatureMenuContainer>
        This is a feature
      </FeatureMenuContainer>
    </>
  )
}