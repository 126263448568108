import stinkyTofuImg from "../../images/stinky_tofu.png";
import shachaBeefImg from "../../images/shacha_beef.png";
import spicyBeefImg from "../../images/spicy_beef.png";
import sichuanSpicyImg from "../../images/sichuan_spicy.png";
import garlicChickenImg from "../../images/garlic_chicken.png";
import thaiTomYumImg from "../../images/thai_tom_yum.png";
import tonkotsuImg from "../../images/tonkotsu.png";
import koreanArmyStewImg from "../../images/korean_army_stew.png";
import seafoodImg from "../../images/seafood.png";
import tomatoCheeseFoamImg from "../../images/tomato_cheese_foam.png";
import creamyCheeseImg from "../../images/creamy_cheese.png";
import vegetableImg from "../../images/vegetable.png";

export const hotPotData = [
  {
    id: "stinky-tofu",
    name: "Stinky Tofu Hot Pot",
    price: "17.95",
    image: stinkyTofuImg,
    spicy: 0,
    nuts: true,
    veggie: false,
    ingredients: [
      "Stinky Tofu",
      "Intestine",
      "Pork",
      "Clam",
      "Blood Cake",
      "Fish Cake",
      "Meatball",
      "Squid Ball",
      "Tempura",
      "Quail Egg",
      "Enoki Mushroom",
      "Napa Cabbage",
      "Chives",
      "Pickled Mustard",
      "Tomato",
      "Taro",
      "Cilantro"
    ]
  },
  {
    id: "shacha-beef",
    name: "Shacha Beef Hot Pot",
    price: "15.95",
    image: shachaBeefImg,
    spicy: 0,
    nuts: true,
    veggie: false,
    ingredients: [
      "Beef",
      "Meatball",
      "Fish Ball",
      "Fish Cake",
      "Tempura",
      "Frozen Tofu",
      "Bean Curd",
      "Tofu Skin Roll",
      "Napa Cabbage",
      "Corn",
      "Enoki Mushroom",
      "Glass Noodle",
      "Scallion",
      "Cilantro",
    ]
  },
  {
    id: "spicy-beef",
    name: "Spicy Beef Hot Pot",
    price: "15.95",
    image: spicyBeefImg,
    spicy: 3,
    nuts: true,
    veggie: false,
    ingredients: [
      "Beef",
      "Meatball",
      "Intestine",
      "Blood Cake",
      "Giant Fish Ball",
      "Squid Ball",
      "Squid Ring",
      "Tempura",
      "Enoki Mushroom",
      "Frozen Tofu",
      "Fried Tofu",
      "White Cabbage",
      "Taro",
      "Seaweed Knot",
      "Corn",
      "Ramen",
      "Scallion",
      "Cilantro",
    ]
  },
  {
    id: "sichuan-spicy",
    name: "Sichuan Spicy Hot Pot",
    price: "19.95",
    image: sichuanSpicyImg,
    spicy: 2,
    nuts: false,
    veggie: false,
    ingredients: [
      "Pork",
      "Meatball",
      "Tripes",
      "Intestine",
      "Artery",
      "Blood",
      "Spam",
      "Squid Ball",
      "Enoki Mushroom",
      "Wood Ear",
      "Frozen Tofu",
      "Fried Tofu",
      "Bean Curd",
      "Napa Cabbage",
      "Vermicelli Knot",
      "Potato",
      "Pepper",
      "Minced Garlic",
      "Scallion",
      "Cilantro",
    ]
  },
  {
    id: "garlic-chicken",
    name: "Garlic Chicken Hot Pot",
    price: "13.95",
    image: garlicChickenImg,
    spicy: 0,
    nuts: false,
    veggie: false,
    ingredients: [
      "Chicken",
      "Frozen Tofu",
      "Tofu Skin Roll",
      "Enoki Mushroom",
      "White Mushroom",
      "Napa Cabbage",
      "Broccoli",
      "Corn",
      "Potato",
      "Rice Cake",
      "Udon",
    ]
  },
  {
    id: "thai-tom-yum",
    name: "Thai Tom Yum Hot Pot",
    price: "15.95",
    image: thaiTomYumImg,
    spicy: 1,
    nuts: false,
    veggie: false,
    ingredients: [
      "Pork",
      "Fish Fillet",
      "Shrimp",
      "Clam",
      "Mussel",
      "Egg",
      "Squid Ring",
      "Squid Ball",
      "Chikuwa",
      "Fish Roe Ball",
      "Fish Tofu",
      "Frozen Tofu",
      "Seaweed Knot",
      "Enoki Mushroom",
      "Napa Cabbage",
      "Tomato",
      "Rice Cake",
      "Scallion",
    ]
  },
  {
    id: "tonkotsu",
    name: "Tonkotsu Hot Pot",
    price: "15.95",
    image: tonkotsuImg,
    spicy: 0,
    nuts: false,
    veggie: false,
    ingredients: [
      "Chasu Pork",
      "Squid Ball",
      "Fish Cake",
      "Tempura",
      "Chikuwa",
      "Frozen Tofu",
      "Enoki Mushroom",
      "White Mushroom",
      "Wood Ear Slices",
      "White Cabbage",
      "Corn Kernels",
      "Udon",
      "Scallion",
      "Seaweed"
    ]
  },
  {
    id: "korean-army-stew",
    name: "Korean Army Stew Hot Pot",
    price: "19.95",
    image: koreanArmyStewImg,
    spicy: 1,
    nuts: false,
    veggie: false,
    ingredients: [
      "Pork",
      "Baby Sausage",
      "Spam",
      "Egg",
      "Cheese",
      "Frozen Tofu",
      "Fish Tofu",
      "Enoki Mushroom",
      "White Cabbage",
      "Kimchi",
      "Chives",
      "Onion",
      "Rice Cake",
      "Ramen",
      "Scallion",
    ]
  },
  {
    id: "seafood",
    name: "Seafood Hot Pot",
    price: "15.95",
    image: seafoodImg,
    spicy: 0,
    nuts: false,
    veggie: false,
    ingredients: [
      "Fish Fillet",
      "Shrimp",
      "Clam",
      "Mussel",
      "Egg",
      "Squid Ring",
      "Squid Ball",
      "Chikuwa",
      "Fish Roe Ball",
      "Fish Tofu",
      "Frozen Tofu",
      "Seaweed Knot",
      "Enoki Mushroom",
      "Napa Cabbage",
      "Rice Cake",
      "Scallion",
    ]
  },
  {
    id: "tomato-cheese-foam",
    name: "Tomato Cheese Foam Hot Pot",
    price: "15.95",
    image: tomatoCheeseFoamImg,
    spicy: 0,
    nuts: false,
    veggie: false,
    ingredients: [
      "Beef",
      "Squid Ball",
      "Fish Cake",
      "Chikuwa",
      "Fish Tofu",
      "Frozen Tofu",
      "Enoki Mushroom",
      "Napa Cabbage",
      "Broccoli",
      "Tomato",
      "Taro",
      "Corn Kernels",
      "Cheese Foam"
    ]
  },
  {
    id: "beef-sukiyaki",
    name: "Beef Sukiyaki Hot Pot",
    price: "15.95",
    image: null,
    spicy: 0,
    nuts: false,
    veggie: false,
    ingredients: [
      "Beef",
      "Fish Cake",
      "Chikuwa",
      "Egg",
      "Frozen Tofu",
      "Enoki Mushroom",
      "White Mushroom",
      "Mushroom Slices",
      "Wood Ear",
      "Vermicelli Knot",
      "Seaweed Knot",
      "Napa Cabbage",
      "Carrot",
      "Corn",
      "Scallion",
      "Seaweed",
    ]
  },
  {
    id: "beef-pickle-pepper",
    name: "Beef & Pickled Pepper Hot Pot",
    price: "15.95",
    image: null,
    spicy: 3,
    nuts: false,
    veggie: false,
    ingredients: [
      "Beef",
      "Tripes",
      "Artery",
      "Meatball",
      "Wood Ear",
      "Frozen Tofu",
      "Fried Tofu",
      "Bean Curd",
      "Enoki Mushroom",
      "Vermicelli Knot",
      "Tomato",
      "Napa Cabbage",
      "Potato",
      "Scallion",
      "Cilantro",
    ]
  },
  {
    id: "curry-fish-cake",
    name: "Curry Fish Cake Hot Pot",
    price: "13.95",
    image: null,
    spicy: 0,
    nuts: false,
    veggie: false,
    ingredients: [
      "Pork",
      "Fish Ball",
      "Crab Stick",
      "Tempura",
      "Enoki Mushroom",
      "Tofu Skin Roll",
      "Napa Cabbage",
      "Tomato",
      "Corn",
      "Potato",
      "Scallion",
    ]
  },
  {
    id: "lamb-pickle",
    name: "Lamb & Pickle Hot Pot",
    price: "15.95",
    image: null,
    spicy: 0,
    nuts: false,
    veggie: false,
    ingredients: [
      "Lamb",
      "Fish Cake",
      "Crab Stick",
      "Tempura",
      "Egg",
      "Frozen Tofu",
      "Tofu Skin Roll",
      "Enoki Mushroom",
      "Napa Cabbage",
      "Pickled Cabbage",
      "Taro",
      "Glass Noodle",
      "Scallion",
      "Cilantro",
    ]
  },
  {
    id: "creamy-cheese",
    name: "Creamy Cheese Hot Pot",
    price: "13.95",
    image: creamyCheeseImg,
    spicy: 0,
    nuts: false,
    veggie: false,
    ingredients: [
      "Pork",
      "Cheese",
      "Fish Cake",
      "Squid Ball",
      "Tempura",
      "Chikuwa",
      "Crab Stick",
      "Enoki Mushroom",
      "White Mushroom",
      "Frozen Tofu",
      "Napa Cabbage",
      "Broccoli",
      "Tomato",
      "Corn",
      "Rice Cake",
      "Scallion",
    ]
  },
  {
    id: "vegetable",
    name: "Vegetable Hot Pot",
    price: "13.95",
    image: vegetableImg,
    spicy: 0,
    nuts: false,
    veggie: true,
    ingredients: [
      "Enoki Mushroom",
      "Wood Ear",
      "Frozen Tofu",
      "Bean Curd",
      "Tofu Skin Roll",
      "Napa Cabbage",
      "White Cabbage",
      "Broccoli",
      "Tomato",
      "Corn",
      "Seaweed Knot",
      "Rice Cake",
    ]
  },
];