import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GiChiliPepper, GiPeanut, GiLindenLeaf } from 'react-icons/gi';

import {
  MenuContainer,
  MenuBackground,
} from '../common';
import {
  HotPotDetailBackButton,
  HotPotDetailWrapper,
  HotPotDetailTitle,
  HotPotDetailPrice,
  HotPotDetailImageWrapper,
  HotPotDetailImage,
  HotPotDetailIngredientsWrapper,
  HotPotDetailIngredient,
  HotPotDetailAddonLink,
} from './HotPotDetailElements';
import {
  HotPotItemNotes,
  HotPotItemNoteWrapper
} from '../HotPotMenuSection/HotPotMenuElements';

export default function HotPotDetail(props) {

  const { id } = useParams();
  const navigate = useNavigate();
  const item = props.hotPotData.find(datum => datum.id === id);
  const { t } = useTranslation();

  return (
    <div className='flex flex-col flex-1 justify-center items-center'>
      {id}
      <MenuBackground />
      <HotPotDetailBackButton onClick={() => navigate(-1)} />
      <MenuContainer>
        <HotPotDetailWrapper>
          {(item.spicy > 0 || item.nuts || item.veggie) &&
            <HotPotItemNotes>
              {item.spicy > 0 &&
                <HotPotItemNoteWrapper>
                  {[...Array(item.spicy)].map((x, i) => <GiChiliPepper key={item.id + '-spicy-' + i} className="-mx-1 text-red-600" />)}
                </HotPotItemNoteWrapper>
              }
              {item.nuts &&
                <HotPotItemNoteWrapper>
                  <GiPeanut className="text-peanut" />
                </HotPotItemNoteWrapper>
              }
              {item.veggie &&
                <HotPotItemNoteWrapper>
                  <GiLindenLeaf className="text-green-600" />
                </HotPotItemNoteWrapper>
              }
            </HotPotItemNotes>
          }
          <HotPotDetailTitle>
            {t(item.id)}
          </HotPotDetailTitle>
          <HotPotDetailPrice>
            {item.price}
          </HotPotDetailPrice>
          {item.image &&
            <HotPotDetailImageWrapper>
              <HotPotDetailImage src={item.image} alt={item.name} />
            </HotPotDetailImageWrapper>
          }
          {item.ingredients &&
            <HotPotDetailIngredientsWrapper>
              {item.ingredients.map(ingredient => (
                <HotPotDetailIngredient key={'ingredient-' + ingredient}>{t(ingredient)}</HotPotDetailIngredient>
              ))}
            </HotPotDetailIngredientsWrapper>
          }
          <HotPotDetailAddonLink>
            <Link to='/addons'>{t('Want to add something extra? Take a look here!')}</Link>
          </HotPotDetailAddonLink>
        </HotPotDetailWrapper>
      </MenuContainer>
    </div>
  )
};