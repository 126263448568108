import React from "react";
import { Link, Outlet, Routes, Route } from "react-router-dom";
import { GiChiliPepper, GiPeanut, GiLindenLeaf } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';

import { hotPotData } from "./Data";
import {
  MenuBackground,
  MenuContainer,
  MenuWrapper,
  MenuTitle,
} from '../common'
import {
  HotPotItemWrapper,
  HotPotItemImage,
  HotPotItemImageWrapper,
  HotPotItemTitle,
  HotPotItemPrice,
  HotPotItemNotes,
  HotPotItemNoteWrapper,

} from "./HotPotMenuElements";
import HotPotDetail from "../HotPotDetailSection";

export default function HotPotMenu(props) {
  return (
    <>
      <Routes>
        <Route index element={<HotPotList />} />
        <Route path=':id' element={<HotPotDetail hotPotData={hotPotData} />} />
      </Routes>

      <Outlet />
    </>
  )
}

function HotPotList() {
  const { t } = useTranslation();
  return (
    <>
      <MenuBackground />
      <MenuContainer>
        <MenuWrapper>
          <MenuTitle>
            {t('hotpot-title')}
          </MenuTitle>
          {hotPotData.map((item, i) => (
            <Link
              key={'link-' + item.id}
              to={{
                pathname: item.id,
                state: { item: item }
              }}
            >
              <HotPotItemWrapper key={item.id}>
                {item.image &&
                  <HotPotItemImageWrapper>
                    <HotPotItemImage src={item.image} alt={item.name} />
                  </HotPotItemImageWrapper>
                }
                <HotPotItemTitle>
                  {i + 1 + '. ' + t(item.id)}
                </HotPotItemTitle>
                <HotPotItemPrice>
                  {item.price}
                </HotPotItemPrice>
                {(item.spicy > 0 || item.nuts || item.veggie) &&
                  <HotPotItemNotes>
                    {item.spicy > 0 &&
                      <HotPotItemNoteWrapper>
                        {[...Array(item.spicy)].map((x, i) => <GiChiliPepper key={item.id + '-spicy-' + i} className="-mx-1 text-red-600" />)}
                      </HotPotItemNoteWrapper>
                    }
                    {item.nuts &&
                      <HotPotItemNoteWrapper>
                        <GiPeanut className="text-peanut" />
                      </HotPotItemNoteWrapper>
                    }
                    {item.veggie &&
                      <HotPotItemNoteWrapper>
                        <GiLindenLeaf className="text-green-600" />
                      </HotPotItemNoteWrapper>
                    }
                  </HotPotItemNotes>
                }
              </HotPotItemWrapper>
            </Link>
          ))}
        </MenuWrapper>
      </MenuContainer>
    </>
  )
}