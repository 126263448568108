export const menuSections = [
  {
    id: 'featured',
    name: 'Featured',
    path: '/',
    type: 'featured'
  },
  {
    id: 'hotpot',
    name: 'Hot Pot',
    path: 'hotpot',
    type: 'hotpot'
  },
  {
    id: 'hotpot-addons',
    name: 'Hot Pot Addons',
    path: 'addons',
    type: 'normal'
  },
  {
    id: 'appetizer',
    name: 'Appetizer',
    path: 'appetizer',
    type: 'normal',
  },
  {
    id: 'rice',
    name: 'Rice Dishes',
    path: 'rice',
    type: 'normal'
  },
  {
    id: 'drinks',
    name: 'Drinks',
    path: 'drinks',
    type: 'normal'
  },
  {
    id: 'drink-toppings',
    name: 'Drink Toppings',
    path: 'toppings',
    type: 'normal'
  },
  {
    id: 'dessert',
    name: 'Dessert',
    path: 'dessert',
    type: 'normal'
  },
];