import React from "react";
import { GiChiliPepper, GiPeanut, GiLindenLeaf } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';

import { sectionData } from "./Data";
import {
  MenuBackground,
  MenuContainer,
  MenuWrapper,
  MenuTitle,
} from '../common'
import {
  NormalItemsWrapper,
  NormalItemWrapper,
  NormalItemTitleWrapper,
  NormalItemTitle,
  NormalItemPrice,
  NormalItemNotes,
  NormalItemNote,
} from "./NormalMenuElements";

export default function NormalMenu(props) {

  const { t } = useTranslation();
  const items = sectionData[props.sectionId].dishes;

  return (
    <>
      <MenuBackground />
      <MenuContainer>
        <MenuWrapper>
          <MenuTitle>
            {t(props.sectionId)}
          </MenuTitle>
          <NormalItemsWrapper>
            {items && items.map((item, i) => (
              <NormalItemWrapper key={item.id} endOfSection={item.endOfSection}>
                <NormalItemTitleWrapper>
                  <NormalItemTitle>
                    {i + 1 + '. ' + t(item.id)}
                  </NormalItemTitle>
                  {(item.spicy || item.nuts || item.veggie) &&
                    <NormalItemNotes>
                      {item.spicy > 0 &&
                        <NormalItemNote>
                          {[...Array(item.spicy)].map((x, i) => <GiChiliPepper key={item.id + '-spicy-' + i} className="-mx-1 text-red-600" />)}
                        </NormalItemNote>
                      }
                      {item.nuts &&
                        <NormalItemNote>
                          <GiPeanut className="text-peanut" />
                        </NormalItemNote>
                      }
                      {item.veggie &&
                        <NormalItemNote>
                          <GiLindenLeaf className="text-green-600" />
                        </NormalItemNote>
                      }
                    </NormalItemNotes>}
                </NormalItemTitleWrapper>
                <NormalItemPrice>{item.price}</NormalItemPrice>
              </NormalItemWrapper>
            ))}
          </NormalItemsWrapper>
        </MenuWrapper>
      </MenuContainer>
    </>
  )
}