import Menu from './Menu';
import { ReactComponent as Logo } from './images/logo.svg';
import { LanguageSwitch } from './components/common';

function App() {
  return (
    <>
      <div className='flex flex-col bg-zinc-900 text-stone-100 min-h-screen'>
        <div className='flex sticky px-6 top-0 h-16 bg-black z-40 justify-center items-center'>
          <div className='text-little-pot-gold w-10 h-10'>
            <Logo />
          </div>
          <div className='absolute right-6'>
            <LanguageSwitch />
          </div>
        </div>
        <div className='flex flex-col flex-1'>
          <Menu />
        </div>
      </div>
    </>
  );
}

export default App;
