export const sectionData = {
  'appetizer': {
    dishes: [
      {
        id: 'steamed-bun',
        name: 'Steamed Bun with Pork (2pc)',
        price: 7.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'popcorn-chicken',
        name: 'Popcorn Chicken (Spicy / Mild)',
        price: 7.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'fried-chicken-strips',
        name: 'Fried Chicken Strips (Spicy / Mild)',
        price: 7.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'fried-chicken-wings',
        name: 'Fried Chicken Wings (Spicy / Mild)',
        price: 9.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'crispy-intestines',
        name: 'Crispy Intestines',
        price: 9.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'fried-oyster',
        name: 'Fried Oysters',
        price: 9.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'bbq-pig-feet',
        name: 'BBQ Pig Feed',
        price: 7.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'grilled-squid',
        name: 'Grilled Squid',
        price: 7.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'takoyaki',
        name: 'Takoyaki',
        price: 7.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'grilled-sausage',
        name: 'Grilled Sausage',
        price: 6.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'mala-spicy-sausage',
        name: 'Mala Spicy Sausage',
        price: 6.95,
        spicy: 1,
        nuts: false,
        veggie: false,
      },
      {
        id: 'purple-fries',
        name: 'Purple Fries',
        price: 7.95,
        spicy: 0,
        nuts: false,
        veggie: true,
      },
      {
        id: 'french-fries',
        name: 'French Fries',
        price: 6.95,
        spicy: 0,
        nuts: false,
        veggie: true,
      },
      {
        id: 'veggie-croquette',
        name: 'Veggie Croquette',
        price: 7.95,
        spicy: 0,
        nuts: false,
        veggie: true,
      },
      {
        id: 'fried-stinky-tofu',
        name: 'Fried Stinky Tofu',
        price: 7.95,
        spicy: 0,
        nuts: false,
        veggie: true,
      },
      {
        id: 'vegetable-spring-roll',
        name: 'Vegetable Spring Roll',
        price: 6.95,
        spicy: 0,
        nuts: false,
        veggie: true,
      },
    ],
  },
  'hotpot-addons': {
    dishes: [
      {
        id: 'addon-beef',
        name: 'Beef',
        price: 4.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-lamb',
        name: 'Lamb',
        price: 4.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-pork',
        name: 'Pork',
        price: 3.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-chicken',
        name: 'Chicken',
        price: 3.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-shrimp',
        name: 'Shrimp',
        price: 4.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-blood',
        name: 'Blood',
        price: 4.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-spam',
        name: 'Spam',
        price: 3.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-meatball',
        name: 'Mealball',
        price: 2.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-fish-fillet',
        name: 'Fish Fillet',
        price: 4.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-quail-egg',
        name: 'Quail Egg',
        price: 3.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-frozen-tofu',
        name: 'Frozen Tofu',
        price: 2.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-fish-cake',
        name: 'Fish Cake',
        price: 2.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-crab-stick',
        name: 'Crab Stick',
        price: 3.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-tofu-skin-roll',
        name: 'Tofu Skin Roll',
        price: 2.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-enoki-mushroom',
        name: 'Enoki Mushroom',
        price: 2.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-taro',
        name: 'Taro',
        price: 2.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-ramen',
        name: 'Ramen',
        price: 2.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-udon',
        name: 'Udon',
        price: 2.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'addon-glass-noodle',
        name: 'Glass Noodle',
        price: 2.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
    ]
  },
  'rice': {
    dishes: [
      {
        id: 'bbq-steak-rice',
        name: 'BBQ Steak Rice in Stone Pot',
        price: 14.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'curry-rice-omelette',
        name: 'Curry Rice Omelette with Chicken',
        price: 13.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'fried-pork-chop-rice',
        name: 'Fried Pork Chop Rice Bowl',
        price: 10.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'braised-pork-rice',
        name: 'Braised Pork Rice Bowl',
        price: 9.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'chicken-leg-rice',
        name: 'Chicken Leg Rice Bowl',
        price: 9.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
      {
        id: 'popcorn-chicken-rice',
        name: 'Popcorn Chicken Rice Bowl',
        price: 9.95,
        spicy: 0,
        nuts: false,
        veggie: false,
      },
    ]
  },
  'drinks': {
    dishes: [
      {
        id: 'hot-jasmine-tea',
        name: 'Hot Jasmine Tea',
        price: '1.50',
      },
      {
        id: 'soda-drink',
        name: 'Coke / Diet Coke / Sprite',
        price: 1.95,
      },
      {
        id: 'japanese-soda',
        name: 'Japanese Soda (Yuzu/Plum/Citrus)',
        price: 3.95,
      },
      {
        id: 'plum-juice',
        name: 'Plum Juice',
        price: 4.95,
      },
      {
        id: 'vanilla-sprite-float',
        name: 'Vanilla Sprite Float',
        price: 4.95,
      },
      {
        id: 'chocolate-coke-float',
        name: 'Chocolate Coke Float',
        price: 4.95,
        endOfSection: true,
      },
      {
        id: 'passion-fruit-tea',
        name: 'Passion Fruit Tea',
        price: 5.95,
      },
      {
        id: 'peach-green-tea',
        name: 'Peach Green Tea',
        price: 5.95,
      },
      {
        id: 'mango-green-tea',
        name: 'Mango Green Tea',
        price: 5.95,
      },
      {
        id: 'fresh-fruit-green-tea',
        name: 'Fresh Fruit Green Tea',
        price: 6.95,
      },
      {
        id: 'grapefruit-green-tea',
        name: 'Grapefruit Green Tea',
        price: 6.95,
      },
      {
        id: 'citrus-green-tea',
        name: 'Citrus Green Tea',
        price: 6.95,
      },
      {
        id: 'black-grape-yogurt-tea',
        name: 'Black Grape Yogurt Tea',
        price: 6.95,
      },
      {
        id: 'strawberry-yogurt-tea',
        name: 'Strawberry Yogurt Tea',
        price: 6.95,
        endOfSection: true,
      },
      {
        id: 'taro-milk-tea',
        name: 'Taro Milk Tea',
        price: 5.95,
      },
      {
        id: 'original-milk-tea',
        name: 'Original Milk Tea',
        price: 5.95,
      },
      {
        id: 'thai-milk-tea',
        name: 'Thai Milk Tea',
        price: 5.95,
      },
      {
        id: 'tofu-pudding-milk-tea',
        name: 'Tofu Pudding Milk Tea',
        price: 5.95,
      },
      {
        id: 'oreo-milk-tea',
        name: 'Oreo Milk Tea',
        price: 6.95,
      },
      {
        id: 'milk-with-brown-sugar',
        name: 'Milk with Brown Sugar',
        price: 6.95,
      },
      {
        id: 'diy-milk',
        name: 'Milk with Choice of Any 3 Toppings',
        price: 6.95,
        endOfSection: true,
      },
      {
        id: 'strawberry-slush',
        name: 'Strawberry Slush / + Cheese Foam',
        price: '6.95 / 7.95',
      },
      {
        id: 'black-grape-slush',
        name: 'Black Grape Slush / + Cheese Foam',
        price: '6.95 / 7.95',
      },
      {
        id: 'watermelon-slush',
        name: 'Watermelon Slush / + Cheese Foam',
        price: '6.95 / 7.95',
      },
      {
        id: 'grapefruit-slush',
        name: 'Grapefruit Slush / + Cheese Foam',
        price: '6.95 / 7.95',
      },
    ]
  },
  "drink-toppings": {
    dishes: [
      {
        id: 'topping-cheese-foam',
        name: 'Cheese Foam',
        price: 1.25,
      },
      {
        id: 'topping-bubble',
        name: 'Bubble',
        price: '0.50',
      },
      {
        id: 'topping-jelly',
        name: 'Jelly',
        price: '0.50',
      },
      {
        id: 'topping-grass-jelly',
        name: 'Grass Jelly',
        price: '0.50',
      },
      {
        id: 'topping-chia-seed',
        name: 'Chia Seed',
        price: '0.50',
      },
      {
        id: 'topping-sago-pearl',
        name: 'Sago Pearl',
        price: '0.50',
      },
      {
        id: 'topping-popping-boba',
        name: 'Popping Boba',
        price: 0.75,
      },
      {
        id: 'topping-coffee-pudding',
        name: 'Coffee Pudding',
        price: 0.75,
      },
      {
        id: 'topping-agar',
        name: 'Agar',
        price: 0.75,
      },
      {
        id: 'topping-tofu-pudding',
        name: 'Tofu Pudding',
        price: 0.75,
      },
      {
        id: 'topping-ice-jelly',
        name: 'Ice Jelly',
        price: 0.75,
      },
      {
        id: 'topping-taro',
        name: 'Taro',
        price: 0.75,
      },
    ]
  },
  "dessert": {
    dishes: [
      {
        id: 'raindrop-jelly',
        name: 'Raindrop Jelly with Brown Sugar',
        price: 4.95,
      },
      {
        id: 'tofu-pudding-brown-sugar',
        name: 'Tofu Pudding with Brown Sugar',
        price: 4.95,
      },
      {
        id: 'grass-jelly-milk-tea',
        name: 'Grass Jelly Milk Tea',
        price: 4.95,
      },
      {
        id: 'peach-resin-tremella',
        name: 'Peach Resin Tremella',
        price: 6.95,
      },
      {
        id: 'fruit-coconut-milk',
        name: 'Fruit Coconut Milk',
        price: 6.95,
      },
      {
        id: 'taro-sago-coconut-milk',
        name: 'Taro Sago Coconut Milk',
        price: 6.95,
      },
      {
        id: 'mango-coconut-milk',
        name: 'Mango Coconut Milk',
        price: 6.95,
      },
      {
        id: 'mango-crepe-cake',
        name: 'Mango Crêpe Cake',
        price: 6.95,
      },
      {
        id: 'ice-cream',
        name: 'Chocolate / Vanilla Ice Cream',
        price: 2.95,
      },
    ]
  },
}