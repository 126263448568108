import React from 'react';
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { menuSections } from './MenuData';
import FeatureMenu from './components/FeatureMenuSection';
import HotPotMenu from './components/HotPotMenuSection';
import NormalMenu from './components/NormalSection';
import HotPotDetail from './components/HotPotDetailSection';

import { hotPotData } from "./components/HotPotMenuSection/Data";

function Menu() {
  const { t } = useTranslation();
  const activeClass = 'py-4 border-b-2 border-little-pot-gold';
  const inactiveClass = 'text-little-pot-gold text-sm'
  return (
    <div id='menu' className='flex flex-col flex-1 font-body-text'>
      <BrowserRouter>
        <div id='top-bar' className='flex flex-col sticky top-16 z-30 font-title-text'>
          <div className='flex sticky top-16 text-sm bg-zinc-900 justify-center scrollbar-hide'>
            <div className='flex px-4 py-4 gap-8 text-little-pot-gold snap-x overflow-scroll scroll-pl-4 scrollbar-hide'>
              {menuSections.map(section => (
                <div key={'section-' + section.id} className='whitespace-nowrap snap-start uppercase'>
                  <NavLink to={section.path} className={({ isActive }) => (isActive ? activeClass : inactiveClass)}>
                    {t(section.id)}
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div id='content' className='flex flex-col flex-1'>
          <Routes>
            <Route exact path='/' element={<FeatureMenu />} />
            {menuSections.slice(1).map(section => (
              section.type === 'hotpot' ?
                <Route key={'route-' + section.id} path={section.path + '/*'} element={<HotPotMenu />} /> :
                <Route
                  key={'route-' + section.id}
                  path={section.path}
                  element={<NormalMenu sectionId={section.id} />}
                />
            ))}
            <Route path='/test' element={<HotPotDetail item={hotPotData[0]} />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default Menu;
