import React from 'react';
import { MdOutlineArrowBackIosNew, MdInfoOutline } from 'react-icons/md';

export const HotPotDetailWrapper = ({ children }) => (
  <div className='flex flex-col justify-center items-center p-8 gap-2'>
    {children}
  </div>
);

export const HotPotDetailBackButton = ({ onClick }) => (
  <div className='absolute top-36 left-6 text-2xl text-little-pot-gold cursor-pointer' onClick={onClick}>
    <MdOutlineArrowBackIosNew />
  </div>
)

export const HotPotDetailTitle = ({ children }) => (
  <div className='text-little-pot-gold text-xl font-title-text uppercase'>
    {children}
  </div>
);

export const HotPotDetailPrice = ({ children }) => (
  <div className='text-md text-stone-100'>
    {children}
  </div>
);

export const HotPotDetailImageWrapper = ({ children }) => (
  <div className='p-1 w-64 w-64 -mt-4 -mb-2 justify-center items-center'>
    {children}
  </div>
);

export const HotPotDetailImage = ({ src, alt, ...rest }) => (
  <img
    {...rest}
    src={src}
    alt={alt}
    className='drop-shadow-hotpot rotate-45'
    onContextMenu={(e) => e.preventDefault()}
  />
);

export const HotPotDetailIngredientsWrapper = ({ children }) => (
  <div className='grid grid-cols-3 gap-y-2 gap-x-4'>
    {children}
  </div>
);

export const HotPotDetailIngredient = ({ children }) => (
  <span className='text-sm text-center text-stone-400 w-24'>
    {children}
  </span>
);

export const HotPotDetailAddonLink = ({ children }) => (
  <div className='text-sm text-little-pot-gold mt-4 items-center underline decoration-dotted'>
    <MdInfoOutline className='inline-block mr-1'/>{children}
  </div>
);